import { AccessRelationship } from "@istari/istari-client/dist/src/openapi";
import { UseMutationResult } from "@tanstack/react-query";
import React, { memo } from "react";
import { useAuth } from "react-oidc-context";

import { useGetModelAccessById } from "../../api/useModels";
import { useListOrgUsers } from "../../api/useUsers";
import { LoadingSpinner } from "../../assets/svg";
import { UserGrantType } from "../../enums";
import { getCanUserArchive } from "../../permissions";

import Modal from "../Modal";

interface ArchiveModalProps {
  isOpen: boolean;
  modelId: string;
  name: string;
  archiveFunc: UseMutationResult<any, unknown, any, unknown>;
  title: string;
  onHide: () => void;
}

const ArchiveModal = memo(({ isOpen, modelId, name, archiveFunc, title, onHide }: ArchiveModalProps) => {
  const { data: modelAccessList, isError, isLoading: isLoadingModelAccess } = useGetModelAccessById(modelId, true);
  const { data: orgUsersData } = useListOrgUsers();
  const auth = useAuth();
  const currentUserSubId = auth?.user?.profile.sub;
  const currentUser = orgUsersData?.find((orgUser) => orgUser.providerUserId === currentUserSubId);
  const currentUserModelPermission = modelAccessList?.find(
    (userGrant: AccessRelationship) => userGrant.subjectId === currentUser?.id,
  )?.relation as UserGrantType;

  const content = (
    <div className="items-center justify-between w-full">
      <div className="color-gray-700">
        Are you sure you want to archive <strong>{name}</strong>?
      </div>
    </div>
  );

  const getContent = () => {
    if (isLoadingModelAccess) {
      return (
        <div className="flex items-center justify-center">
          <LoadingSpinner width="30px" height="30px" />
        </div>
      );
    }
    if (isError || !getCanUserArchive(currentUserModelPermission)) {
      return <div className="color-gray-700">You do not have permission to archive this file.</div>;
    }

    return content;
  };

  const getLabel = () => {
    if (isLoadingModelAccess) {
      return "Loading";
    }
    if (isError || !getCanUserArchive(currentUserModelPermission)) {
      return "OK";
    }
    return "Archive";
  };

  return (
    <Modal
      open={isOpen}
      title={title}
      content={getContent()}
      includeCancelButton
      actionButton={{
        dataTestId: `${title}-archive-btn`,
        disabled: archiveFunc.isPending,
        label: getLabel(),
        loading: archiveFunc.isPending,
        variant: getCanUserArchive(currentUserModelPermission) ? "danger" : "primary",
        clickHandler: getCanUserArchive(currentUserModelPermission) ? () => archiveFunc.mutate({ modelId }) : onHide,
      }}
      onHide={onHide}
    />
  );
});

export default ArchiveModal;
