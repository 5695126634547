export const DEFAULT_PAGE_SIZE = 10;
export const INPUT_TYPE_DEBOUNCE = 400;
export const LONG_TEXT_MAX_LENGTH = 256;
export const MILISECONDS_TO_REFRESH_EXTRACTION_STATUS = 10000;
export const NAME_INPUT_MAX_LENGTH = 128;
export const PASSWORD_MIN_LENGTH = 12;
export const PDF_PAGES_CUTOFF = 200;
export const TEXT_FILE_DISPLAY_MAX_SIZE = 500000; // bytes
export const THROTTLE_MS = 500;
export const ZITADEL_RELOAD_DELAY = 300;
