import React, { memo } from "react";

interface TextInputProps {
  ariaLabel?: string;
  dataTestId?: string;
  disabled?: boolean;
  icon?: React.JSX.Element;
  isError?: boolean;
  placeholder?: string;
  required?: boolean;
  size?: "sm" | "md";
  type?: "text" | "string" | "number";
  value?: string;
  onChange?: (_value: string) => void;
}

const TextInput = memo(
  ({
    ariaLabel,
    dataTestId,
    disabled = false,
    icon,
    isError = false,
    placeholder = "",
    required,
    size = "md",
    type = "text",
    value = "",
    onChange,
  }: TextInputProps) => {
    const bgColor = disabled ? "bg-gray-200" : "bg-white";
    const borderColor = isError ? "border-red-400" : "border-gray-300";
    const height = size === "sm" ? "h-23px" : "h-33px";
    const inputTextColor = disabled ? "color-gray-400" : "color-gray-900";
    return (
      <div
        className={`flex px-10px py-5px gap-x-5px items-center m-none w-full overflow-hidden box-border rounded border border-solid ${bgColor} ${borderColor}`}
      >
        {icon || null}
        <input
          aria-label={ariaLabel}
          className={`p-none text-md w-full ${height} border-none placeholder:text-gray-400 focus:outline-none ${bgColor} ${inputTextColor}`}
          data-testid={dataTestId}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          type={type === "string" ? "text" : type}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value)}
        />
      </div>
    );
  },
);

export default TextInput;
